<template>
  <div>
    <div v-if="solicitudesCliente.length > 0">
      <div class="pt-5" v-for="(solicitudes, idx) in showSolicitudes" :key="idx">
        <CommonExpansionPanel>
          <template v-slot:title-panel>
            Datos de Solicitud #{{ solicitudes.id }}
          </template>
          <v-expansion-panel-content>
            <SolicitudDetalleCliente :solicitudData="solicitudes" />
          </v-expansion-panel-content>
        </CommonExpansionPanel>
      </div>
      <div class="pt-5">
        <div
          style="display: flex; justify-content: center; align-items: center;"
        >
          <v-pagination
            v-model="page"
            :length="pages"
            circle
            color="#0070C0"
          ></v-pagination>
        </div>
      </div>
    </div>
    <div v-else>
      <v-col
        cols="12"
        md="12"
        style="align-items: center; text-align: center; padding: 5%;"
      >
        <span class="titulo-header">Sin resultados que mostrar</span>
      </v-col>
    </div>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import SolicitudDetalleCliente from "./SolicitudDetalleCliente.vue";

export default {
  components: {
    CommonExpansionPanel,
    SolicitudDetalleCliente,
  },
  props: {
    solicitudesCliente: Array,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      switch_data: 1,
      page: 1,
      limit: 5,
      filtro:''
    };
  },
  computed:{
    pages() {
      const totalPages = Math.ceil(this.solicitudesFiltradas.length / this.limit);
      return totalPages < 1 ? 1 : totalPages;
    },
    solicitudesFiltradas() {
      if (!this.filtro) {
        return this.solicitudesCliente;
      }
      const filtroMin = this.filtro.toLowerCase().trim();
      return this.solicitudesCliente.filter((solcitud) => {
        return solcitud.id.toLowerCase().includes(filtroMin);
      });
    },
    indiceInicio() {
      return (this.page - 1) * this.limit;
    },
    indiceFinal() {
      return this.page * this.limit - 1;
    },
    showSolicitudes() {
      return this.solicitudesFiltradas.slice(this.indiceInicio, this.indiceFinal + 1);
    },
  },
  methods: {
    changeComponent(component) {
      this.switch_data = component;
    },
  },
};
</script>
